import CreateBadgeModalButton from "@/admin/experiences/badges/CreateBadgeModalButton"
import { CreateExperienceFormQuery } from "@/admin/experiences/create/__generated__/CreateExperienceFormQuery.graphql"
import {
  CreateExperienceInput,
  ExperienceTemplateKind,
} from "@/admin/experiences/create/__generated__/useCreateExperienceFormMutation.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useConfettiCannon } from "@/core/context/ConfettiContext"
import { useLabel } from "@/core/context/LabelsContext"
import { useOnboardingChecklistContext } from "@/core/context/OnboardingChecklistContext"
import FormStore from "@/core/form/store/FormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import ExperienceSettingsAccessRadioGroup from "@/product/settings/ExperienceSettingsAccessRadioGroup"
import ExperienceSettingsDurationFields from "@/product/settings/ExperienceSettingsDurationFields"
import {
  PricingKind,
  ProductLandingPageInput,
} from "@/product/settings/__generated__/ExperienceSettingsFormMutation.graphql"
import RelayEnvironment from "@/relay/RelayEnvironment"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import Form from "@components/form/Form"
import {
  DiscoButtonSkeleton,
  DiscoDivider,
  DiscoFormControl,
  DiscoInput,
  DiscoInputSkeleton,
} from "@disco-ui"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import { observer } from "mobx-react-lite"
import { useLazyLoadQuery } from "react-relay"
import { generatePath, useHistory } from "react-router-dom"
import ConnectionHandler from "relay-connection-handler-plus"
import { commitLocalUpdate, graphql } from "relay-runtime"

export type CreateExperienceFormState = Omit<CreateExperienceInput, "template"> & {
  template?: ExperienceTemplateKind
  isPaid: boolean
  price: number
  landingPage?: ProductLandingPageInput | undefined | null
  benefits: {
    membershipPlanId: GlobalID
  }[]
}

interface Props {
  form: FormStore<CreateExperienceFormState>
  onSuccess?: () => void
}

function CreateExperienceForm(props: Props) {
  const { form, onSuccess } = props
  const history = useHistory()
  const experienceLabel = useLabel("admin_experience")
  const confettiCannon = useConfettiCannon()
  const activeOrganization = useActiveOrganization()!

  const { curriculumGeneration } = useFeatureFlags()

  const { completeChecklistItem, isChecklistItemCompleted } =
    useOnboardingChecklistContext()

  const { organization } = useLazyLoadQuery<CreateExperienceFormQuery>(
    graphql`
      query CreateExperienceFormQuery($id: ID!) {
        organization: node(id: $id) {
          ... on Organization {
            id
            ...useExperienceSettingsSelectedPlans_membershipPlansFragment
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
    }
  )

  if (!organization) return null

  return (
    <Form
      id={"CreateExperienceForm"}
      testid={"CreateExperienceForm"}
      onSubmit={handleSubmit}
    >
      <DiscoFormControl
        title={"Badge & Name"}
        sublabel={`How your ${experienceLabel.singular} appears in your community navigation.`}
        error={Boolean(form.errorsByField.name || form.errorsByField.badgeId)}
        errorMessages={form.errorsByField.name || form.errorsByField.badgeId}
      >
        <DiscoInput
          value={form.state.name}
          onChange={(e) => (form.state.name = e.target.value)}
          fullWidth
          data-testid={"CreateExperienceForm.name"}
          padding={"0 10px 0 0"}
          startAdornment={<CreateBadgeModalButton badge={form.state.badge!} />}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      </DiscoFormControl>
      <DiscoFormControl
        title={"Description"}
        sublabel={`Add a short description about your ${experienceLabel.singular}.`}
        error={Boolean(form.errorsByField.description)}
        errorMessages={form.errorsByField.description}
      >
        <DiscoInput
          value={form.state.description}
          onChange={(e) => (form.state.description = e.target.value)}
          multiline
          minRows={3}
          fullWidth
          data-testid={"CreateExperienceForm.description"}
        />
      </DiscoFormControl>
      <DiscoDivider marginBottom={2.5} marginTop={0} />
      <ExperienceSettingsAccessRadioGroup
        form={form}
        hideMembershipPlans
        organizationKey={organization}
      />

      {form.state.template !== "self_paced_course" && (
        <>
          <DiscoDivider marginBottom={2.5} marginTop={2.5} />
          <ExperienceSettingsDurationFields form={form} mode={"create"} />
        </>
      )}
    </Form>
  )

  async function handleSubmit() {
    const isCreatingCourse =
      form.state.template === "self_paced_course" ||
      form.state.template === "cohort_based_course"

    const { didSave, response } = await form.submit(
      {
        template: form.state.template,
        organizationId: form.state.organizationId,
        name: form.state.name,
        description: form.state.description,
        startDate: form.state.startDate,
        endDate: form.state.endDate,
        coverAssetId: form.state.coverAssetId,
        badge: form.state.badge,
        registrationAvailability: form.state.registrationAvailability,
        isAutoJoined: form.state.isAutoJoined,
        membershipBenefits: form.state.benefits.map((b) => ({
          membershipPlanId: b.membershipPlanId,
          kind: "experience",
          pricing: {
            organizationId: form.state.organizationId,
            amountCents: 0,
            kind: "free" as PricingKind,
            frequency: null,
          },
        })),
        navSectionId: form.state.navSectionId,
        skipDefaultModule: curriculumGeneration,
      },
      { connections: [] }
    )

    const productSlug = response?.node?.slug
    if (!productSlug || !didSave) return

    commitLocalUpdate(RelayEnvironment, (store) => {
      const org = store.get(activeOrganization.id)
      if (!org) return

      ConnectionHandler.getConnections(org, "ProductCardGrid__products").forEach(
        (connection) => connection.invalidateRecord()
      )
    })

    confettiCannon()
    if (isCreatingCourse && !isChecklistItemCompleted("create_course")) {
      await completeChecklistItem({
        kind: "create_course",
      })
    }

    history.push({
      pathname: generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
        productSlug,
      }),
      search:
        // If the product is a course, open the curriculum generation prompt modal
        isCreatingCourse ? "?openCurriculumGenPromptModal=1" : undefined,
    })

    onSuccess?.()
  }
}

const CreateExperienceFormSkeleton = () => {
  const experienceLabel = useLabel("admin_experience")

  return (
    <Form
      id={"CreateExperienceForm"}
      testid={"CreateExperienceForm.skeleton"}
      buttons={
        <>
          <DiscoButtonSkeleton width={"80px"} />
          <DiscoButtonSkeleton width={"100px"} />
        </>
      }
    >
      <DiscoFormControl
        title={"Badge & Name"}
        sublabel={`How your ${experienceLabel.singular} appears in your community navigation.`}
      >
        <DiscoInputSkeleton />
      </DiscoFormControl>
      <DiscoFormControl
        title={"Description"}
        sublabel={`Add a short description about your ${experienceLabel.singular}.`}
      >
        <DiscoInputSkeleton />
      </DiscoFormControl>
    </Form>
  )
}

export default Relay.withSkeleton<Props>({
  component: observer(CreateExperienceForm),
  skeleton: CreateExperienceFormSkeleton,
})
