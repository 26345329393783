import { useAssetSubtitlesModal } from "@/admin/asset/tracks/AssetSubtitlesModalContext"
import { AssetTracksListProps } from "@/admin/asset/tracks/AssetTracksList"
import AssetTrackStatusChip from "@/admin/asset/tracks/AssetTrackStatusChip"
import DeleteAssetTrackButton from "@/admin/asset/tracks/DeleteAssetTrackButton"
import { AssetTracksListItemFragment$key } from "@/admin/asset/tracks/__generated__/AssetTracksListItemFragment.graphql"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Language from "@components/language/languageUtils"
import {
  DiscoChipSkeleton,
  DiscoIcon,
  DiscoTable,
  DiscoText,
  DiscoTooltip,
} from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMoreActionsDropdown from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import { TableCell } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  assetTrackKey: AssetTracksListItemFragment$key
  showLanguageColumn?: AssetTracksListProps["showLanguageColumn"]
  hideUpdateButtonFromOverflow?: boolean | null
}

function AssetTracksListItem({
  assetTrackKey,
  showLanguageColumn,
  hideUpdateButtonFromOverflow = false,
}: Props) {
  const { setModalState } = useAssetSubtitlesModal()
  const classes = useStyles()

  const assetTrack = useFragment<AssetTracksListItemFragment$key>(
    graphql`
      fragment AssetTracksListItemFragment on AssetTrack {
        id
        url
        name
        languageCode
        isAutoGenerated
        muxAssetTrackStatus
        trackAsset {
          name
        }
        ...AssetTrackStatusChipFragment
        ...UpdateAssetTrackFormFragment
        ...DeleteAssetTrackButtonFragment
      }
    `,
    assetTrackKey
  )

  const { isAutoGenerated } = assetTrack
  const TESTID = `AssetTracksListItem.${assetTrack.name}`

  const fileName = isAutoGenerated ? assetTrack.name : assetTrack.trackAsset!.name

  const isNotDoneProcessing =
    assetTrack.muxAssetTrackStatus === null ||
    assetTrack.muxAssetTrackStatus === "preparing"

  return (
    <DiscoTable.Row testid={TESTID}>
      {/* Language */}
      {showLanguageColumn && (
        <TableCell>
          <DiscoText variant={"body-sm"} color={"text.secondary"}>
            {assetTrack.languageCode
              ? Language.getNameByCode(assetTrack.languageCode)
              : "-"}
          </DiscoText>
        </TableCell>
      )}

      {/* File name */}
      <TableCell>
        <div className={classes.fileNameContainer}>
          <DiscoText
            variant={"body-sm"}
            color={"text.secondary"}
            truncateText={1}
            title={fileName}
          >
            {fileName}
          </DiscoText>
          {isAutoGenerated && !isNotDoneProcessing && (
            <DiscoTooltip
              content={"This subtitles were generated by AI and may have inaccuracies."}
              placement={"bottom"}
            >
              <span>
                <DiscoIcon icon={"info"} width={20} height={20} />
              </span>
            </DiscoTooltip>
          )}
        </div>
      </TableCell>

      {/* Status */}
      <TableCell>
        <AssetTrackStatusChip assetTrackKey={assetTrack} />
      </TableCell>

      {/* Overflow */}
      <TableCell>
        {isAutoGenerated && isNotDoneProcessing ? null : (
          <DiscoMoreActionsDropdown
            key={`${TESTID}-more-actions`}
            testid={`${TESTID}.more-actions`}
            rotateIcon
            width={48}
            height={48}
          >
            {/* Update */}
            {!isAutoGenerated && !hideUpdateButtonFromOverflow && (
              <DiscoDropdownItem
                title={"Details"}
                testid={`${TESTID}.update`}
                onClick={() =>
                  setModalState({
                    updateAssetTrackFormFragment: assetTrack,
                    view: "update",
                  })
                }
              />
            )}

            {/* Download */}
            {
              <DiscoDropdownItem
                onClick={handleDownload}
                target={"_blank"}
                title={"Download"}
              />
            }

            {/* Delete */}

            <DeleteAssetTrackButton assetTrackKey={assetTrack}>
              {(buttonProps) => {
                return (
                  <DiscoDropdownItem
                    title={"Delete"}
                    testid={`${TESTID}.delete`}
                    {...buttonProps}
                    tooltip={
                      isNotDoneProcessing
                        ? "Subtitles are still being prepared. Please check back later."
                        : null
                    }
                    disabled={isNotDoneProcessing}
                  />
                )
              }}
            </DeleteAssetTrackButton>
          </DiscoMoreActionsDropdown>
        )}
      </TableCell>
    </DiscoTable.Row>
  )

  function handleDownload() {
    if (!assetTrack.url) return
    window.open(assetTrack.url, "_blank")
  }
}

const useStyles = makeUseStyles((theme) => ({
  actionsSkeleton: {
    display: "flex",
    justifyContent: "space-around",
  },
  fileNameContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}))

export function AssetTracksListItemSkeleton() {
  const classes = useStyles()
  return (
    <DiscoTable.Row>
      <TableCell width={"40%"}>
        <Skeleton variant={"rect"} />
      </TableCell>
      <TableCell width={"40%"}>
        <Skeleton variant={"rect"} />
      </TableCell>
      <TableCell>
        <DiscoChipSkeleton />
      </TableCell>
      <TableCell>
        <div className={classes.actionsSkeleton}>
          <Skeleton width={30} />
        </div>
      </TableCell>
    </DiscoTable.Row>
  )
}

export default AssetTracksListItem
