import { EXPERIENCE_TEMPLATES } from "@/admin/experiences/create/ExperienceTemplateCarousel"
import { ExperienceTemplateKind } from "@/admin/experiences/create/__generated__/useCreateExperienceFormMutation.graphql"
import DiscoCard, { DiscoCardProps, DiscoCardSkeleton } from "@disco-ui/card/DiscoCard"
import { TestIDProps } from "@utils/typeUtils"
import React from "react"

interface Props
  extends TestIDProps,
    Pick<DiscoCardProps, "classes" | "variant" | "inCarousel"> {
  template: ExperienceTemplateKind
  onUse(): void
}

function ExperienceTemplateCard(props: Props) {
  const { template, classes, onUse, variant = "default", inCarousel } = props
  const { testid = `ExperienceTemplateCard.${template}` } = props

  const templateConfig = EXPERIENCE_TEMPLATES[template]
  if (!templateConfig) return null

  return (
    <DiscoCard
      testid={testid}
      classes={classes}
      variant={variant}
      icon={React.createElement(templateConfig.icon)}
      title={templateConfig.title}
      description={templateConfig.shortDescription}
      inCarousel={inCarousel}
      onClick={onUse}
    />
  )
}

export function ExperienceTemplateCardSkeleton(props: Pick<Props, "classes">) {
  const { classes } = props
  return <DiscoCardSkeleton testid={"ExperienceTemplateCardSkeleton"} classes={classes} />
}

export default ExperienceTemplateCard
