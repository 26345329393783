import { CreatePathwayFormState } from "@/admin/pathways/hooks/useCreatePathwayForm"
import PathwayGroupProductOption from "@/admin/pathways/PathwayGroupProductOption"
import PathwayGroupProductSelectButton from "@/admin/pathways/PathwayGroupProductSelectButton"
import { PathwaySettingsFormStore } from "@/admin/pathways/settings/PathwaySettingsForm"
import { useLabels } from "@/core/context/LabelsContext"
import MoveIcon from "@/core/ui/iconsax/linear/custom-dots-tune.svg"
import { useAdminProductLabel } from "@/product/util/hook/useProductLabel"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import Form from "@components/form/Form"
import {
  DiscoButton,
  DiscoChip,
  DiscoFormControl,
  DiscoIcon,
  DiscoIconButton,
  DiscoSection,
  DiscoText,
  useModalContext,
} from "@disco-ui"
import DiscoClickToEditInput from "@disco-ui/input/DiscoClickToEditInput"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { TestIDProps } from "@utils/typeUtils"
import { observable, runInAction } from "mobx"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
} from "react-beautiful-dnd"

interface CreatePathwayGroupsFormProps extends TestIDProps {
  form: CreatePathwayFormState | PathwaySettingsFormStore
  onSubmit?: () => void
}
function CreatePathwayGroupsForm({
  testid = "CreatePathwayGroupsForm",
  form,
  onSubmit,
}: CreatePathwayGroupsFormProps) {
  const labels = useLabels()
  const adminCourseLabel = labels.admin_experience
  const memberLabel = labels.organization_member
  const classes = useStyles()
  const modalCtx = useModalContext()
  const [warnDeleteGroup, setWarnDeleteGroup] = useState<number | null>(null)
  const isPublished =
    "status" in form.initialState && form.initialState.status === "published"

  const adminPathwayLabel = useAdminProductLabel("pathway")

  return (
    <Form id={"CreatePathwayGroupsForm"} testid={testid} onSubmit={onSubmit}>
      <DragDropContext onDragEnd={handleDragEnd}>
        {form.state.pathwayGroups.map((group, gIdx) => {
          const { label, tooltip } = getGroupLabelAndExplanation(gIdx)
          const labelColor = gIdx === 0 ? "green" : "orange"
          const groupErrorField = `pathwayGroups.${gIdx}`

          // Access groups outside of the Droppable fn so observer updates work
          const groupsList = group.productIds.map((productId, pIdx) => {
            const productErrorField = `${groupErrorField}.productIds.${pIdx}`
            const cachedProduct = form.state.productCache[productId]
            if (!cachedProduct) return null
            return (
              <Draggable key={productId} draggableId={productId} index={pIdx}>
                {(draggableProvided, snapshot) => {
                  /**
                   * Offset the top position of the draggable item by the modal's contentRef top position
                   * https://github.com/atlassian/react-beautiful-dnd/issues/1881#issuecomment-1652549194
                   */
                  const offset = modalCtx?.contentRef?.getBoundingClientRect().top ?? 0
                  return (
                    <div
                      {...draggableProvided.draggableProps}
                      ref={draggableProvided.innerRef}
                      style={{
                        ...draggableProvided.draggableProps.style,
                        left: "auto !important",
                        top: snapshot.isDragging
                          ? (draggableProvided.draggableProps.style as DraggingStyle)
                              .top - offset
                          : "auto !important",
                      }}
                    >
                      <DiscoFormControl
                        errorMessages={form.errorsByField[productErrorField]}
                      >
                        <DiscoSection
                          testid={`${testid}.group.${gIdx}.item.${pIdx}`}
                          groovyDepths={"insideCard"}
                          className={classes.product}
                        >
                          {draggableProvided && (
                            <div
                              style={{ width: "16px" }}
                              {...draggableProvided.dragHandleProps}
                            >
                              <MoveIcon />
                            </div>
                          )}

                          <PathwayGroupProductOption
                            testid={`${testid}.group.${gIdx}`}
                            option={{
                              id: productId,
                              name: cachedProduct.name,
                              badge: cachedProduct.badge,
                            }}
                          />

                          <DiscoIconButton
                            testid={`${testid}.group.${gIdx}.remove-product-button.${cachedProduct.name}`}
                            onClick={() => handleRemoveProduct(gIdx, pIdx)}
                            height={24}
                            width={24}
                            svgStyles={{ width: 16, height: 16 }}
                          >
                            <DiscoIcon icon={"iconsax.custom-x"} />
                          </DiscoIconButton>
                        </DiscoSection>
                      </DiscoFormControl>
                    </div>
                  )
                }}
              </Draggable>
            )
          })

          return (
            <DiscoFormControl
              // eslint-disable-next-line react/no-array-index-key
              key={gIdx}
              errorMessages={form.errorsByFields(
                groupErrorField,
                `${groupErrorField}.id`,
                `${groupErrorField}.productIds`
              )}
            >
              <DiscoSection groovyDepths={"insideCard"}>
                <DiscoFormControl
                  errorMessages={form.errorsByField[`${groupErrorField}.title`]}
                >
                  <div className={classes.groupHeader}>
                    <DiscoClickToEditInput
                      testid={`${testid}.group.${gIdx}.title`}
                      value={group.title}
                      onChange={(v) => handleTitleChange(v, gIdx)}
                      placeholder={"Group Title"}
                    />
                    <DiscoChip
                      label={label}
                      color={labelColor}
                      tooltip={{ content: tooltip }}
                    />
                    <DiscoIconButton
                      testid={`${testid}.group.${gIdx}.remove-group`}
                      onClick={() => {
                        // Warn if deleting an existing group that may have members in it
                        if (isPublished && "id" in form.state.pathwayGroups[gIdx]) {
                          setWarnDeleteGroup(gIdx)
                          return
                        }
                        handleRemoveGroup(gIdx)
                      }}
                      height={24}
                      width={24}
                      svgStyles={{ width: 16, height: 16 }}
                    >
                      <DiscoIcon icon={"iconsax.custom-x"} />
                    </DiscoIconButton>
                  </div>
                </DiscoFormControl>

                <Droppable droppableId={`${gIdx}`}>
                  {(droppableProvided) => {
                    return (
                      <ul
                        {...droppableProvided.droppableProps}
                        ref={droppableProvided.innerRef}
                      >
                        {groupsList.length ? (
                          groupsList
                        ) : (
                          <DiscoText
                            color={"text.secondary"}
                            variant={"body-sm"}
                            align={"center"}
                            marginBottom={3}
                          >{`No ${adminCourseLabel.plural} in this ${adminPathwayLabel.singular} Group.`}</DiscoText>
                        )}
                        {droppableProvided.placeholder}
                      </ul>
                    )
                  }}
                </Droppable>

                <PathwayGroupProductSelectButton
                  testid={`${testid}.group.${gIdx}.add-product-select`}
                  form={form}
                  groupIdx={gIdx}
                  renderButton={(buttonProps) => (
                    <DiscoButton
                      testid={`${testid}.group.${gIdx}.add-product-select.button`}
                      leftIcon={"add"}
                      variant={"dashed"}
                      color={"grey"}
                      width={"100%"}
                      onClick={buttonProps.onClick}
                    >
                      {`Add ${adminCourseLabel.singular}`}
                    </DiscoButton>
                  )}
                />
              </DiscoSection>
            </DiscoFormControl>
          )
        })}
      </DragDropContext>

      <DiscoButton
        testid={`${testid}.add-group.button`}
        leftIcon={"add"}
        color={"grey"}
        variant={"outlined"}
        onClick={handleAddGroup}
      >
        {`Add ${adminPathwayLabel.singular} Group`}
      </DiscoButton>

      <DiscoWarningModal
        testid={`${testid}.warn-delete-group`}
        title={`Removing ${adminPathwayLabel.singular} Group`}
        modalContentLabel={`Removing ${adminPathwayLabel.singular} Group`}
        isOpen={warnDeleteGroup !== null}
        description={getDeleteGroupWarning()}
        onClose={() => setWarnDeleteGroup(null)}
        confirmationButtonProps={{
          onClick: () => {
            handleRemoveGroup(warnDeleteGroup!)
            setWarnDeleteGroup(null)
          },
          children: "Remove",
        }}
      />
    </Form>
  )

  function handleAddGroup() {
    form.state.pathwayGroups.push({
      title: `${adminPathwayLabel.singular} Group ${form.state.pathwayGroups.length + 1}`,
      productIds: observable.array([]),
    })
  }
  function handleRemoveGroup(idx: number) {
    for (const productId of form.state.pathwayGroups[idx].productIds) {
      delete form.state.productCache[productId]
    }
    form.state.pathwayGroups.splice(idx, 1)
  }
  function handleRemoveProduct(gIdx: number, pIdx: number) {
    const [deletedId] = form.state.pathwayGroups[gIdx].productIds.splice(pIdx, 1)
    delete form.state.productCache[deletedId]
  }
  function handleDragEnd(result: DropResult) {
    if (!result.destination) return
    const fromGroupIdx = Number(result.source.droppableId)
    const startIndex = result.source.index
    const toGroupIdx = Number(result.destination.droppableId)
    const newIndex = result.destination.index
    if (startIndex === newIndex && fromGroupIdx === toGroupIdx) return
    runInAction(() => {
      const { productIds: fromIds } = form.state.pathwayGroups[fromGroupIdx]
      const [productId] = fromIds.splice(startIndex, 1)
      const { productIds: toIds } = form.state.pathwayGroups[toGroupIdx]
      toIds.splice(newIndex, 0, productId)
    })
  }

  function handleTitleChange(value: string, idx: number) {
    form.state.pathwayGroups[idx].title = value
  }

  function getGroupLabelAndExplanation(idx: number) {
    if (idx === 0) {
      return {
        label: "Immediate Access",
        tooltip: `${memberLabel.plural} can immediately see and register for any ${adminCourseLabel.singular} in this ${adminPathwayLabel.singular} Group after registering for the ${adminPathwayLabel.singular}.`,
      }
    }
    return {
      label: "Has Prerequisites",
      tooltip: `${memberLabel.plural} can only see and register for the ${adminCourseLabel.plural} in this ${adminPathwayLabel.singular} Group once they complete all ${adminCourseLabel.plural} in the previous Group.`,
    }
  }

  function getDeleteGroupWarning() {
    if (warnDeleteGroup === null) return ""
    const { title } = form.state.pathwayGroups[warnDeleteGroup]
    if (warnDeleteGroup === form.state.pathwayGroups.length - 1)
      return `'${title}' is the last group in the ${adminPathwayLabel.singular} Sequence. Removing this group will grant ${memberLabel.plural} in this ${adminPathwayLabel.singular} a completion status.`
    return `Removing '${title}' will grant ${memberLabel.singular} access to the next ${adminPathwayLabel.singular} Group in the ${adminPathwayLabel.singular} Sequence.`
  }
}

const useStyles = makeUseStyles((theme) => ({
  groupHeader: {
    display: "grid",
    gridTemplateColumns: "1fr auto auto",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  product: {
    display: "grid",
    gridTemplateColumns: "auto 1fr auto",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}))

export default observer(CreatePathwayGroupsForm)
