import { useAssetSubtitlesModal } from "@/admin/asset/tracks/AssetSubtitlesModalContext"
import AssetTrackFormLanguageSection from "@/admin/asset/tracks/AssetTrackFormLanguageSection"
import AssetTracksList from "@/admin/asset/tracks/AssetTracksList"
import { UpdateAssetTrackFormFragment$key } from "@/admin/asset/tracks/__generated__/UpdateAssetTrackFormFragment.graphql"
import {
  UpdateAssetTrackFormMutation,
  UpdateAssetTrackInput,
} from "@/admin/asset/tracks/__generated__/UpdateAssetTrackFormMutation.graphql"
import { useFormStore } from "@/core/form/store/FormStore"
import Form from "@components/form/Form"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoText } from "@disco-ui"
import { observer } from "mobx-react-lite"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  onClose: VoidFunction
}

const TESTID = "UpdateAssetTrackForm"

function UpdateAssetTrackForm({ onClose }: Props) {
  const { modalState, closeModal } = useAssetSubtitlesModal()

  const assetTrack = useFragment<UpdateAssetTrackFormFragment$key>(
    graphql`
      fragment UpdateAssetTrackFormFragment on AssetTrack {
        id
        languageCode
        videoAssetId
        videoAsset {
          ...AssetTrackFormLanguageSectionFragment
        }
      }
    `,
    modalState?.updateAssetTrackFormFragment || null
  )

  const form = useFormStore<UpdateAssetTrackFormMutation, UpdateAssetTrackInput>(
    graphql`
      mutation UpdateAssetTrackFormMutation($input: UpdateAssetTrackInput!) {
        response: updateAssetTrack(input: $input) {
          node {
            id
            languageCode
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      id: assetTrack?.id || "",
      languageCode: assetTrack?.languageCode,
    }
  )

  if (!assetTrack) return null

  return (
    <Form
      id={TESTID}
      testid={TESTID}
      onSubmit={handleSubmit}
      buttons={
        <>
          <DiscoButton color={"grey"} variant={"outlined"} onClick={onClose}>
            {"Cancel"}
          </DiscoButton>
          <Form.SubmitButton
            form={form}
            id={TESTID}
            testid={`${TESTID}.submit-button`}
            disabled={!form.changedState.languageCode}
          >
            {"Save Changes"}
          </Form.SubmitButton>
        </>
      }
    >
      <AssetTrackFormLanguageSection form={form} assetKey={assetTrack.videoAsset} />

      <DiscoText variant={"body-md-600"}>{"Add subtitle or caption media"}</DiscoText>
      <DiscoText variant={"body-sm"} color={"text.secondary"} marginBottom={2}>
        {"Help viewers understand and follow the narrative"}
      </DiscoText>
      <AssetTracksList
        assetId={assetTrack.videoAssetId}
        showLanguageColumn={false}
        assetTrackIds={assetTrack ? [assetTrack.id] : null}
        hideAddSubtitleButton
        hideUpdateButtonFromOverflow
      />
    </Form>
  )

  async function handleSubmit() {
    if (!assetTrack) return

    const { didSave, response } = await form.submit({
      id: assetTrack.id,
      languageCode: form.changedState.languageCode,
    })

    if (!didSave || !response?.node) return

    displaySuccessToast({
      testid: `${TESTID}.success-toast`,
      message: "Subtitles updated!",
    })

    closeModal()
  }
}

export default observer(UpdateAssetTrackForm)
