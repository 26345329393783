import { useOnboardingChecklistContext } from "@/core/context/OnboardingChecklistContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import ProgressIcon from "@/product/course/curriculum/common/ProgressIcon"
import Relay from "@/relay/relayUtils"
import EmptyCheckmarkIcon from "@assets/disco/icons/onboarding-checklist/custom-empty-checkbox.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIcon, DiscoLink, DiscoText, DiscoTooltip } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { MouseEvent } from "react"
import { useHistory } from "react-router-dom"

type Props = TestIDProps

function AdminOnboardingChecklistProgressIcon({ testid }: Props) {
  const classes = useStyles()
  const theme = useTheme()
  const { sections, completionPercentage, nextIncompleteItem, setActiveItemId } =
    useOnboardingChecklistContext()

  const history = useHistory()

  if (!sections.length) {
    return null
  }

  // If the checklist is complete, don't show the progress icon
  if (completionPercentage === 100 || !nextIncompleteItem) {
    return null
  }

  const { content } = nextIncompleteItem

  return (
    <DiscoLink to={ROUTE_NAMES.ADMIN.ONBOARDING_CHECKLIST}>
      <DiscoTooltip
        data-testid={`${testid}.progress-tooltip`}
        placement={"left"}
        content={renderChecklistTooltipContent()}
        classes={{
          tooltip: classes.tooltip,
        }}
        interactive
        backgroundColor={theme.palette.groovy.blue[400]}
      >
        <div>
          <ProgressIcon
            completion={completionPercentage / 100}
            testid={"CurriculumItemListItemTemplate"}
            variant={"percentage"}
            width={40}
            height={40}
          />
        </div>
      </DiscoTooltip>
    </DiscoLink>
  )

  function renderChecklistTooltipContent() {
    return (
      <div className={classes.tooltipContainer}>
        <DiscoText variant={"body-xs-600"} color={"common.white"}>
          {"Getting Started Guide"}
        </DiscoText>
        <DiscoText variant={"body-xs"} color={"common.white"}>
          {"Your next task"}
        </DiscoText>
        <DiscoContainerButton
          onClick={handleChecklistItemClick}
          className={classes.itemButton}
        >
          <EmptyCheckmarkIcon width={16} height={16} />
          <DiscoText variant={"body-sm"} color={"common.white"}>
            {content.name}
          </DiscoText>
          <DiscoIcon
            icon={"chevron"}
            color={"common.white"}
            rotate={"90"}
            width={16}
            height={16}
          />
        </DiscoContainerButton>
      </div>
    )
  }

  function handleChecklistItemClick(e: MouseEvent) {
    if (!nextIncompleteItem) return

    e.stopPropagation()

    setActiveItemId(nextIncompleteItem.id)
    history.push(ROUTE_NAMES.ADMIN.ONBOARDING_CHECKLIST)
  }
}

const useStyles = makeUseStyles((theme) => ({
  tooltip: {
    width: 364,
    maxWidth: "100%",
  },
  tooltipContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
  },
  itemButton: {
    padding: theme.spacing(2),
    display: "grid",
    gap: theme.spacing(1.25),
    alignItems: "center",
    gridTemplateColumns: "16px 1fr 16px",
    backgroundColor: "rgba(255, 255, 255, 0.16)",
    borderRadius: theme.measure.borderRadius.big,
  },
}))

export default Relay.withSkeleton({
  component: AdminOnboardingChecklistProgressIcon,
  skeleton: () => null,
})
