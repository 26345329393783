/**
 * @generated SignedSource<<0c0ef117ec6f4086099223908d00b3ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StartAssetTranscriptionJobInput = {
  assetId: string;
  productId?: string | null;
};
export type StartAssetTranscriptionJobButtonMutation$variables = {
  input: StartAssetTranscriptionJobInput;
  connections: ReadonlyArray<string>;
};
export type StartAssetTranscriptionJobButtonMutation$data = {
  readonly response: {
    readonly node: {
      readonly id: string;
      readonly asset: {
        readonly " $fragmentSpreads": FragmentRefs<"InteractivePlayerTranscriptionStatusFragment">;
      };
    } | null;
    readonly assetTrack: {
      readonly videoAsset: {
        readonly " $fragmentSpreads": FragmentRefs<"InteractivePlayerSubtitlesTabContentFragment">;
      };
      readonly " $fragmentSpreads": FragmentRefs<"AssetTracksListItemFragment">;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type StartAssetTranscriptionJobButtonMutation = {
  variables: StartAssetTranscriptionJobButtonMutation$variables;
  response: StartAssetTranscriptionJobButtonMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "languageCode",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "StartAssetTranscriptionJobButtonMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v2/*: any*/),
        "concreteType": "StartAssetTranscriptionJobResponse",
        "kind": "LinkedField",
        "name": "startAssetTranscriptionJob",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetTranscriptionJob",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Asset",
                "kind": "LinkedField",
                "name": "asset",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "InteractivePlayerTranscriptionStatusFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetTrack",
            "kind": "LinkedField",
            "name": "assetTrack",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AssetTracksListItemFragment"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Asset",
                "kind": "LinkedField",
                "name": "videoAsset",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "InteractivePlayerSubtitlesTabContentFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "StartAssetTranscriptionJobButtonMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v2/*: any*/),
        "concreteType": "StartAssetTranscriptionJobResponse",
        "kind": "LinkedField",
        "name": "startAssetTranscriptionJob",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetTranscriptionJob",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Asset",
                "kind": "LinkedField",
                "name": "asset",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "videoAssetsEnabledAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssetTranscriptionJob",
                    "kind": "LinkedField",
                    "name": "transcriptionJob",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sieveJobErrorReason",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssetTranscription",
                    "kind": "LinkedField",
                    "name": "transcription",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetTrack",
            "kind": "LinkedField",
            "name": "assetTrack",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isAutoGenerated",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "muxAssetTrackStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Asset",
                "kind": "LinkedField",
                "name": "trackAsset",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "videoAssetId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Asset",
                "kind": "LinkedField",
                "name": "videoAsset",
                "plural": false,
                "selections": [
                  {
                    "alias": "notAutoGeneratedSubtitleTracks",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "isAutoGenerated",
                        "value": false
                      }
                    ],
                    "concreteType": "AssetTrackNodeConnection",
                    "kind": "LinkedField",
                    "name": "subtitleTracks",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssetTrackNodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssetTrack",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "subtitleTracks(isAutoGenerated:false)"
                  },
                  (v3/*: any*/),
                  {
                    "alias": "deleteSubtitleTracks",
                    "args": null,
                    "concreteType": "AssetTrackNodeConnection",
                    "kind": "LinkedField",
                    "name": "subtitleTracks",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalCount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "assetTrack",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "AssetTrackNodeEdge"
              }
            ]
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "45d83f0dcfd0f9515157af605efdabdb",
    "id": null,
    "metadata": {},
    "name": "StartAssetTranscriptionJobButtonMutation",
    "operationKind": "mutation",
    "text": "mutation StartAssetTranscriptionJobButtonMutation(\n  $input: StartAssetTranscriptionJobInput!\n) {\n  response: startAssetTranscriptionJob(input: $input) {\n    node {\n      id\n      asset {\n        ...InteractivePlayerTranscriptionStatusFragment\n        id\n      }\n    }\n    assetTrack {\n      ...AssetTracksListItemFragment\n      videoAsset {\n        ...InteractivePlayerSubtitlesTabContentFragment\n        id\n      }\n      id\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n\nfragment AssetTrackFormLanguageSectionFragment on Asset {\n  notAutoGeneratedSubtitleTracks: subtitleTracks(isAutoGenerated: false) {\n    edges {\n      node {\n        id\n        languageCode\n      }\n    }\n  }\n}\n\nfragment AssetTrackStatusChipFragment on AssetTrack {\n  muxAssetTrackStatus\n  isAutoGenerated\n}\n\nfragment AssetTracksListItemFragment on AssetTrack {\n  id\n  url\n  name\n  languageCode\n  isAutoGenerated\n  muxAssetTrackStatus\n  trackAsset {\n    name\n    id\n  }\n  ...AssetTrackStatusChipFragment\n  ...UpdateAssetTrackFormFragment\n  ...DeleteAssetTrackButtonFragment\n}\n\nfragment DeleteAssetTrackButtonFragment on AssetTrack {\n  id\n  videoAsset {\n    id\n    deleteSubtitleTracks: subtitleTracks {\n      totalCount\n    }\n  }\n}\n\nfragment InteractivePlayerSubtitlesTabContentFragment on Asset {\n  id\n}\n\nfragment InteractivePlayerTranscriptionStatusFragment on Asset {\n  id\n  videoAssetsEnabledAt\n  transcriptionJob {\n    status\n    sieveJobErrorReason\n    id\n  }\n  transcription {\n    id\n  }\n}\n\nfragment UpdateAssetTrackFormFragment on AssetTrack {\n  id\n  languageCode\n  videoAssetId\n  videoAsset {\n    ...AssetTrackFormLanguageSectionFragment\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e4d1bd0580b47d6e24363f63beb3d766";

export default node;
