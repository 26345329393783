import CreateExperienceModal from "@/admin/experiences/create/CreateExperienceModal"
import ExperienceTemplateCard from "@/admin/experiences/create/ExperienceTemplateCard"
import { EXPERIENCE_TEMPLATE_CARDS_ORDER } from "@/admin/experiences/create/ExperienceTemplateCarousel"
import { ExperienceTemplateKind } from "@/admin/experiences/create/__generated__/useCreateExperienceFormMutation.graphql"
import { useLabel } from "@/core/context/LabelsContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { GlobalID } from "@/relay/RelayTypes"
import { DiscoModal, DiscoModalProps, DiscoText } from "@disco-ui"
import { useState } from "react"

type Props = Pick<DiscoModalProps, "onClose"> & {
  navSectionId?: GlobalID
  coursesOnly?: boolean
}

function ExperienceTemplatesModal(props: Props) {
  const { onClose, navSectionId, coursesOnly } = props

  const classes = useStyles()
  const experienceLabel = useLabel("admin_experience")
  const [state, setState] = useState<{
    template: ExperienceTemplateKind
    step: "preview" | "use"
  } | null>(null)

  return (
    <>
      <DiscoModal
        {...props}
        isOpen={!state?.template}
        title={`New ${experienceLabel.singular}`}
        modalContentLabel={`New ${experienceLabel.singular}`}
        width={"720px"}
        maxWidth={"90vw"}
        disableTransition
        body={
          <>
            <DiscoText variant={"body-sm"} marginBottom={2.5} color={"text.secondary"}>
              {`Build a thriving learning community by creating unique ${experienceLabel.plural}. Start fresh, or use one of our templates to get a quick start.`}
            </DiscoText>
            <div className={classes.root}>
              {EXPERIENCE_TEMPLATE_CARDS_ORDER.map(({ template }) => {
                if (
                  coursesOnly &&
                  template !== "cohort_based_course" &&
                  template !== "self_paced_course"
                )
                  return null

                return (
                  <ExperienceTemplateCard
                    key={template}
                    template={template}
                    variant={"compact"}
                    onUse={() =>
                      setState({
                        template,
                        step: template === "blank" ? "use" : "preview",
                      })
                    }
                  />
                )
              })}
            </div>
          </>
        }
        testid={"ExperienceTemplatesModal.modal"}
      />
      {state?.template && (
        <CreateExperienceModal
          template={state.template}
          onBack={() => setState(null)}
          onClose={onClose}
          navSectionId={navSectionId}
        />
      )}
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    [theme.breakpoints.only("sm")]: {
      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    },
    [theme.breakpoints.only("xs")]: {
      gridTemplateColumns: "minmax(0, 1fr)",
    },
    gap: theme.spacing(2.5),
  },
}))

export default ExperienceTemplatesModal
