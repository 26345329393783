import AdminContentListAIGeneratedFilter from "@/admin/content-library/filters/AdminContentListFilterAIGenerated"
import AdminContentListLabelFilter from "@/admin/content-library/filters/AdminContentListLabelFilter"
import AdminContentListProductFilter from "@/admin/content-library/filters/AdminContentListProductFilter"
import { AdminContentListToolbarState } from "@/admin/content-library/filters/AdminContentListToolbar"
import { AdminContentListFilterDropdownQuery } from "@/admin/content-library/filters/__generated__/AdminContentListFilterDropdownQuery.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import DropdownIcon from "@/core/ui/iconsax/bold/arrow-down.svg"
import Relay from "@/relay/relayUtils"
import { DiscoButton, DiscoDropdown } from "@disco-ui"
import { DiscoTableFilterDropdownSkeleton } from "@disco-ui/table/header/search/DiscoTableFilterDropdown"
import { useTheme } from "@material-ui/core"
import { TestIDProps } from "@utils/typeUtils"
import { useCallback } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"
import AdminContentListCollectionAppFilter from "./AdminContentListCollectionAppFilter"
import AdminContentListInstancesFilter from "./AdminContentListInstancesFilter"

interface AdminContentListFilterDropdownProps extends TestIDProps {
  toolbarState: AdminContentListToolbarState
  setToolbarState: React.Dispatch<React.SetStateAction<AdminContentListToolbarState>>
  hideProduct?: boolean
  hideCollection?: boolean
  hideAIGenerated?: boolean
}

function AdminContentListFilterDropdown({
  testid,
  toolbarState,
  setToolbarState,
  hideProduct = false,
  hideCollection = false,
  hideAIGenerated = false,
}: AdminContentListFilterDropdownProps) {
  const activeOrganization = useActiveOrganization()!
  const theme = useTheme()

  const { node } = useLazyLoadQuery<AdminContentListFilterDropdownQuery>(
    graphql`
      query AdminContentListFilterDropdownQuery($id: ID!) {
        node(id: $id) {
          ... on Organization {
            id
            __typename
            ...AdminContentListProductFilterFragment
            ...AdminContentListCollectionAppFilterFragment
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
    }
  )

  const menuButton = useCallback(
    ({ onClick }) => (
      <DiscoButton
        rightIcon={<DropdownIcon fill={theme.palette.text.primary} />}
        color={"grey"}
        variant={"outlined"}
        onClick={onClick}
        testid={`${testid}.filter-select.header-button`}
      >
        {"Filters"}
      </DiscoButton>
    ),
    [testid, theme.palette.text.primary]
  )

  const organization = Relay.narrowNodeType(node, "Organization")
  if (!organization) return null

  return (
    <DiscoDropdown menuButton={menuButton}>
      <AdminContentListLabelFilter
        filterState={toolbarState}
        setFilterState={(newFilterState) =>
          setToolbarState({ ...toolbarState, ...newFilterState })
        }
      />
      {!hideProduct && (
        <AdminContentListProductFilter
          filterState={toolbarState}
          setFilterState={(newFilterState) =>
            setToolbarState({ ...toolbarState, ...newFilterState })
          }
          organizationKey={organization}
        />
      )}
      {!hideCollection && (
        <AdminContentListCollectionAppFilter
          organizationKey={organization}
          filterState={toolbarState}
          setFilterState={(newFilterState) =>
            setToolbarState({ ...toolbarState, ...newFilterState })
          }
        />
      )}
      <AdminContentListInstancesFilter
        filterState={toolbarState}
        setFilterState={(newFilterState) =>
          setToolbarState({ ...toolbarState, ...newFilterState })
        }
      />
      {!hideAIGenerated && (
        <AdminContentListAIGeneratedFilter
          filterState={toolbarState}
          setFilterState={(newFilterState) =>
            setToolbarState({ ...toolbarState, ...newFilterState })
          }
        />
      )}
    </DiscoDropdown>
  )
}

export default Relay.withSkeleton({
  component: AdminContentListFilterDropdown,
  skeleton: DiscoTableFilterDropdownSkeleton,
})
