import { CreateExperienceFormState } from "@/admin/experiences/create/CreateExperienceForm"
import CreateExperienceModal from "@/admin/experiences/create/CreateExperienceModal"
import ExperienceTemplateCard, {
  ExperienceTemplateCardSkeleton,
} from "@/admin/experiences/create/ExperienceTemplateCard"
import { ExperienceTemplateKind } from "@/admin/experiences/create/__generated__/useCreateExperienceFormMutation.graphql"
import { useLabels } from "@/core/context/LabelsContext"
import spaceCover from "@/core/ui/images/experience-templates/alumni-space.png"
import cohortBasedCourseCover from "@/core/ui/images/experience-templates/cohort-based-course.png"
import AlumniSpaceIcon from "@/core/ui/images/experience-templates/icons/alumni-space.svg"
import BlankIcon from "@/core/ui/images/experience-templates/icons/blank.svg"
import CohortBasedCourseIcon from "@/core/ui/images/experience-templates/icons/cohort-based-course.svg"
import SelfPacedCourseIcon from "@/core/ui/images/experience-templates/icons/self-paced-course.svg"
import SeriesIcon from "@/core/ui/images/experience-templates/icons/series.svg"
import selfPacedCourseCover from "@/core/ui/images/experience-templates/self-paced-course.png"
import seriesCover from "@/core/ui/images/experience-templates/series.png"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoCarousel, { DiscoCarouselSkeleton } from "@disco-ui/carousel/DiscoCarousel"
import { addWeeks, format, startOfWeek } from "date-fns"
import { useState } from "react"

function ExperienceTemplateCarousel() {
  const classes = useStyles()
  const labels = useLabels()

  const [state, setState] = useState<{
    template: ExperienceTemplateKind
    step: "preview" | "use"
  } | null>(null)

  return (
    <>
      <DiscoCarousel
        className={classes.container}
        title={`${labels.admin_experience.singular} Templates`}
        data={EXPERIENCE_TEMPLATE_CARDS_ORDER}
        item={({ template }) => (
          <ExperienceTemplateCard
            key={template}
            template={template}
            onUse={() =>
              setState({ template, step: template === "blank" ? "use" : "preview" })
            }
            inCarousel
          />
        )}
        totalCount={EXPERIENCE_TEMPLATE_CARDS_ORDER.length}
        itemSkeleton={<ExperienceTemplateCardSkeleton />}
      />

      {state?.template && (
        <CreateExperienceModal template={state.template} onClose={() => setState(null)} />
      )}
    </>
  )
}

export const EXPERIENCE_TEMPLATE_CARDS_ORDER: { template: ExperienceTemplateKind }[] = [
  { template: "cohort_based_course" },
  { template: "self_paced_course" },
  { template: "series" },
  { template: "blank" },
]

const useStyles = makeUseStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
  },
}))

export function ExperienceTemplateCarouselSkeleton() {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <DiscoCarouselSkeleton item={<ExperienceTemplateCardSkeleton />} showTitle />
    </div>
  )
}

export const EXPERIENCE_TEMPLATES: Partial<
  Record<
    ExperienceTemplateKind,
    {
      title: string
      icon: React.FC<React.SVGProps<SVGSVGElement>>
      cover: string
      shortDescription: string
      defaults: () => Partial<CreateExperienceFormState>
    }
  >
> = {
  cohort_based_course: {
    title: "Cohort-Based Course",
    cover: cohortBasedCourseCover,
    icon: CohortBasedCourseIcon,
    shortDescription:
      "A social and collaborative learning experience that takes place over a period of days or weeks.",
    defaults: () => {
      const defaultStartDate = startOfWeek(addWeeks(new Date(), 1), {
        weekStartsOn: 1,
      })
      const defaultEndDate = addWeeks(defaultStartDate, 4)
      return {
        registrationAvailability: "public",
        startDate: format(defaultStartDate, "yyyy-MM-dd"),
        endDate: format(defaultEndDate, "yyyy-MM-dd"),
        isAutoJoined: false,
      }
    },
  },
  self_paced_course: {
    title: "Self-Paced Course",
    cover: selfPacedCourseCover,
    icon: SelfPacedCourseIcon,
    shortDescription:
      "An independent course that members can start at any time and progress through at their own pace.",
    defaults: () => ({
      registrationAvailability: "public",
      startDate: null,
      endDate: null,
      isAutoJoined: false,
    }),
  },
  alumni_space: {
    title: "Member Space",
    cover: spaceCover,
    icon: AlumniSpaceIcon,
    shortDescription:
      "A virtual 'place' where members can access community events, resources, and more.",
    defaults: () => ({
      registrationAvailability: "private",
      startDate: null,
      endDate: null,
      isAutoJoined: false,
    }),
  },
  series: {
    title: "Event Series",
    cover: seriesCover,
    icon: SeriesIcon,
    shortDescription:
      "A series of events that focus on a specific topic and occur with a regular cadence.",
    defaults: () => {
      const defaultStartDate = startOfWeek(addWeeks(new Date(), 1), {
        weekStartsOn: 1,
      })
      const defaultEndDate = addWeeks(defaultStartDate, 4)
      return {
        registrationAvailability: "public",
        startDate: format(defaultStartDate, "yyyy-MM-dd"),
        endDate: format(defaultEndDate, "yyyy-MM-dd"),
        isAutoJoined: false,
      }
    },
  },
  blank: {
    title: "Blank",
    shortDescription: "Build a product from scratch using Disco Apps.",
    icon: BlankIcon,
    defaults: () => ({
      template: "blank",
      registrationAvailability: "public",
      startDate: null,
      endDate: null,
      isAutoJoined: false,
    }),
    // No config for a 'blank' template's preview screen as we don't show it.
    cover: "",
  },
}

export default Relay.withSkeleton({
  component: ExperienceTemplateCarousel,
  skeleton: ExperienceTemplateCarouselSkeleton,
})
