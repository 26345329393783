/**
 * @generated SignedSource<<2ffe76d64a815f2bd18c7b43595e6f79>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AuthProviderIDP = "generic" | "okta" | "microsoft_entra" | "google_workspace" | "miniorange" | "%future added value";
export type CreateAuthProviderInput = {
  identityProvider: AuthProviderIDP;
};
export type useCreateAuthProviderMutation$variables = {
  input: CreateAuthProviderInput;
};
export type useCreateAuthProviderMutation$data = {
  readonly response: {
    readonly node: {
      readonly id: string;
      readonly status: string;
      readonly stytchSamlConnectionId: string | null;
      readonly metadataUrl: string | null;
      readonly samlConnection: {
        readonly acs_url: string;
        readonly audience_uri: string;
        readonly id: string;
        readonly identity_provider: string;
      } | null;
      readonly verifiedUserInformation: {
        readonly email: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly authUserId: string;
      } | null;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type useCreateAuthProviderMutation = {
  variables: useCreateAuthProviderMutation$variables;
  response: useCreateAuthProviderMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateAuthProviderResponse",
    "kind": "LinkedField",
    "name": "createAuthProvider",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthProvider",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "stytchSamlConnectionId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "metadataUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SAMLConnection",
            "kind": "LinkedField",
            "name": "samlConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "acs_url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "audience_uri",
                "storageKey": null
              },
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "identity_provider",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthProviderVerifiedUserInformation",
            "kind": "LinkedField",
            "name": "verifiedUserInformation",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "authUserId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCreateAuthProviderMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCreateAuthProviderMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "c6b60eea6d66d729983f821b6bca2645",
    "id": null,
    "metadata": {},
    "name": "useCreateAuthProviderMutation",
    "operationKind": "mutation",
    "text": "mutation useCreateAuthProviderMutation(\n  $input: CreateAuthProviderInput!\n) {\n  response: createAuthProvider(input: $input) {\n    node {\n      id\n      status\n      stytchSamlConnectionId\n      metadataUrl\n      samlConnection {\n        acs_url\n        audience_uri\n        id\n        identity_provider\n      }\n      verifiedUserInformation {\n        email\n        firstName\n        lastName\n        authUserId\n      }\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d40e718a4c0298aae9bf41df701412a0";

export default node;
