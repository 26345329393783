import { PublishPathwayButtonMutation } from "@/admin/pathways/__generated__/PublishPathwayButtonMutation.graphql"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabel } from "@/core/context/LabelsContext"
import { useAdminProductLabel } from "@/product/util/hook/useProductLabel"
import { displayErrorToast, displaySuccessToast } from "@components/toast/ToastProvider"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { TestIDProps } from "@utils/typeUtils"
import { useState } from "react"
import { useMutation } from "react-relay"
import { ConnectionHandler, graphql } from "relay-runtime"

type PublishPathwayButtonProps = {
  children: OverridableDiscoButtonChildren
} & TestIDProps

function PublishPathwayButton({
  testid = "PublishPathwayButton",
  children,
}: PublishPathwayButtonProps) {
  const [showModal, setShowModal] = useState(false)
  const activeProduct = useActiveProduct()

  const permissions = activeProduct?.viewerPermissions

  const [publishPathway, isLoading] = useMutation<PublishPathwayButtonMutation>(graphql`
    mutation PublishPathwayButtonMutation($input: UpdatePathwayInput!) {
      response: updatePathway(input: $input) {
        node {
          id
          status
        }
        errors {
          field
          message
        }
      }
    }
  `)

  if (!permissions?.has("pathways.manage")) return null

  return (
    <>
      <OverridableDiscoButton testid={testid} onClick={openModal}>
        {children}
      </OverridableDiscoButton>
      <PublishPathwayWarningModal
        testid={testid}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        disabled={isLoading}
        handleSubmit={handlePublish}
      />
    </>
  )

  function openModal() {
    setShowModal(true)
  }

  function closeModal() {
    setShowModal(false)
  }

  function handlePublish() {
    publishPathway({
      variables: {
        input: {
          productId: activeProduct!.id,
          status: "published",
        },
      },
      updater: (store) => {
        const productRecord = store.get(activeProduct!.id)
        if (!productRecord) return

        const actionsConnection = ConnectionHandler.getConnection(
          productRecord,
          "ProductAdminDashboardActions__productAdminActions"
        )
        actionsConnection?.invalidateRecord()
      },
      onCompleted({ response }) {
        if (response?.errors?.length) {
          return displayErrorToast(response.errors[0].message)
        }
        displaySuccessToast({
          message: `Pathway has been published`,
          testid: "PublishPathwayButton.success-toast",
        })
        closeModal()
      },
      onError(err) {
        console.error(err)
        displayErrorToast("An unknown error occurred. Please try again.")
      },
    })
  }
}

export function PublishPathwayWarningModal({
  testid = "PublishPathwayWarningModal",
  onClose,
  isOpen,
  handleSubmit,
  isSubmitting,
  disabled,
}: {
  onClose: VoidFunction
  isOpen: boolean
  handleSubmit: VoidFunction
  isSubmitting?: boolean
  disabled?: boolean
} & TestIDProps) {
  const pathwayLabel = useAdminProductLabel("pathway")
  const experienceLabel = useLabel("admin_experience")

  return (
    <DiscoWarningModal
      testid={`${testid}.publish-warning`}
      variant={"primary"}
      icon={"rocket"}
      title={`Publishing ${pathwayLabel.singular}`}
      modalContentLabel={`Publishing ${pathwayLabel.singular}`}
      isOpen={isOpen}
      description={`Are you sure you want to publish this ${pathwayLabel.singular}? You will not be able to change it back to draft, or add any draft ${experienceLabel.plural} to it.`}
      onClose={onClose}
      confirmationButtonProps={{
        onClick: () => {
          handleSubmit()
          onClose()
        },
        children: "Yes, publish it",
        disabled,
        shouldDisplaySpinner: isSubmitting,
      }}
    />
  )
}

export default PublishPathwayButton
