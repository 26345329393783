import CreateExperienceForm from "@/admin/experiences/create/CreateExperienceForm"
import { EXPERIENCE_TEMPLATES } from "@/admin/experiences/create/ExperienceTemplateCarousel"
import useCreateExperienceForm from "@/admin/experiences/create/useCreateExperienceForm"
import { ExperienceTemplateKind } from "@/admin/experiences/create/__generated__/useCreateExperienceFormMutation.graphql"
import { useLabel } from "@/core/context/LabelsContext"
import { GlobalID } from "@/relay/RelayTypes"
import { DiscoButton, DiscoModal, DiscoModalProps } from "@disco-ui"
import { observer } from "mobx-react-lite"

type Props = Pick<DiscoModalProps, "onClose" | "onBack"> & {
  template: ExperienceTemplateKind
  navSectionId?: GlobalID
}

function CreateExperienceModal(props: Props) {
  const { template, onBack, navSectionId, ...rest } = props
  const experienceLabel = useLabel("admin_experience")

  const form = useCreateExperienceForm(template, navSectionId)
  const templateConfig = EXPERIENCE_TEMPLATES[template]

  // Complete the form
  const typeLabel =
    template === "blank" || !templateConfig
      ? experienceLabel.singular
      : templateConfig.title
  return (
    <DiscoModal
      {...rest}
      isOpen
      onBack={onBack}
      title={`Create ${typeLabel}`}
      modalContentLabel={`Create ${typeLabel}`}
      width={"720px"}
      maxWidth={"90vw"}
      disableTransition
      body={<CreateExperienceForm form={form} onSuccess={props.onClose} />}
      buttons={[
        <DiscoButton
          key={"cancel"}
          color={"grey"}
          variant={"outlined"}
          onClick={props.onClose}
          testid={"CreateExperienceModal.cancel.button"}
        >
          {"Cancel"}
        </DiscoButton>,
        <DiscoButton
          key={"create-experience"}
          type={"submit"}
          form={"CreateExperienceForm"}
          testid={"CreateExperienceModal.save-as-draft.button"}
          shouldDisplaySpinner={form.isSubmitting}
          disabled={form.isSubmitting}
        >
          {"Save as Draft"}
        </DiscoButton>,
      ]}
      testid={"CreateExperienceButton.modal"}
    />
  )
}

export default observer(CreateExperienceModal)
