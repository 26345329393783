import { CreateExperienceFormState } from "@/admin/experiences/create/CreateExperienceForm"
import { EXPERIENCE_TEMPLATES } from "@/admin/experiences/create/ExperienceTemplateCarousel"
import {
  ExperienceTemplateKind,
  useCreateExperienceFormMutation,
} from "@/admin/experiences/create/__generated__/useCreateExperienceFormMutation.graphql"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import { useTheme } from "@material-ui/core/styles"
import { graphql } from "relay-runtime"

function useCreateExperienceForm(
  template: ExperienceTemplateKind,
  navSectionId?: GlobalID
) {
  const activeOrganization = useActiveOrganization()!
  const theme = useTheme()

  return useFormStore<useCreateExperienceFormMutation, CreateExperienceFormState>(
    graphql`
      mutation useCreateExperienceFormMutation(
        $input: CreateExperienceInput!
        $connections: [ID!]!
      ) {
        response: createExperience(input: $input) {
          node {
            id
            slug
            status
            viewerMembership
              @prependNode(
                connections: $connections
                edgeTypeName: "ProductMembershipEdge"
              ) {
              id
              product {
                status
                ...MyExperiencesListItemFragment
              }
            }
            organization {
              # Refresh channels list from any defaults created by template.
              ...StreamChatContext_ChannelsFragment
              products(type: "course", orderBy: nav) {
                edges {
                  node {
                    id
                  }
                }
              }
              pathwayProductOptions {
                totalCount
              }
              ...ProductsSidebar_NavSectionsFragment
              ...ProductsSidebarList_OrganizationFragment
            }
            ...ProductCardFragment
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      organizationId: activeOrganization.id,
      name: "",
      template,
      badge: {
        kind: "icon",
        color: theme.palette.primary.main,
        icon: null,
      },
      price: 0,
      benefits: [
        {
          membershipPlanId: activeOrganization.defaultMembershipPlan.id,
        },
      ],
      landingPage: null,
      isPaid: false,
      ...EXPERIENCE_TEMPLATES[template]?.defaults(),
      navSectionId,
    }
  )
}

export default useCreateExperienceForm
