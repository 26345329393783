import ExperienceTemplatesModal from "@/admin/experiences/create/ExperienceTemplatesModal"
import { GlobalID } from "@/relay/RelayTypes"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"

type CreateExperienceButtonProps = OverridableDiscoButtonProps & {
  navSectionId?: GlobalID
  /** If true, only show the course product templates */
  coursesOnly?: boolean
}

function CreateExperienceButton(props: CreateExperienceButtonProps) {
  const { children, navSectionId, coursesOnly, ...rest } = props

  return (
    <OverridableDiscoButton
      {...rest}
      modal={({ isOpen, onClose }) => {
        // Reset form state on each open
        if (!isOpen) return null
        return (
          <ExperienceTemplatesModal
            onClose={onClose}
            navSectionId={navSectionId}
            coursesOnly={coursesOnly}
          />
        )
      }}
    >
      {children}
    </OverridableDiscoButton>
  )
}

export default CreateExperienceButton
