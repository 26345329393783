import { SelectOption } from "@disco-ui"
import DiscoTableFilterDropdown from "@disco-ui/table/header/search/DiscoTableFilterDropdown"

type AdminContentListAIGeneratedOption = SelectOption<boolean | null>

export type AdminContentListAIGeneratedFilterState = {
  isAIGenerated: AdminContentListAIGeneratedOption | null
}

interface Props {
  filterState: AdminContentListAIGeneratedFilterState
  setFilterState: (state: AdminContentListAIGeneratedFilterState) => void
}

export default function AdminContentListAIGeneratedFilter({
  filterState,
  setFilterState,
}: Props) {
  const options: AdminContentListAIGeneratedOption[] = [
    {
      value: null,
      title: "Any",
    },
    {
      value: true,
      title: "AI-Generation",
    },
    {
      value: false,
      title: "User",
    },
  ]

  const selectedOption = filterState.isAIGenerated

  return (
    <DiscoTableFilterDropdown
      testid={"AdminContentList.created-by"}
      isNested
      options={options}
      onOptionSelect={handleSelect}
      selectedOption={selectedOption}
      dropdown={{ label: "Created By", icon: null }}
    />
  )

  function handleSelect(option: AdminContentListAIGeneratedOption | null) {
    if (typeof option?.value === "boolean")
      setFilterState({
        ...filterState,
        isAIGenerated: option,
      })
    else
      setFilterState({
        ...filterState,
        isAIGenerated: null,
      })
  }
}
