import { useAssetSubtitlesModal } from "@/admin/asset/tracks/AssetSubtitlesModalContext"
import AssetTrackFormLanguageSection, {
  AssetTrackFormLanguageSectionSkeleton,
} from "@/admin/asset/tracks/AssetTrackFormLanguageSection"
import {
  CreateAssetTrackFormMutation,
  CreateAssetTrackInput,
  LanguageCode,
} from "@/admin/asset/tracks/__generated__/CreateAssetTrackFormMutation.graphql"
import { CreateAssetTrackFormQuery } from "@/admin/asset/tracks/__generated__/CreateAssetTrackFormQuery.graphql"
import AttachmentListItem from "@/content/attachment/list/item/AttachmentListItem"
import FormStore, { useFormStore } from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import FileDropzone, { SUBTITLE_FILE_TYPES } from "@components/dropzone/FileDropzone"
import Form from "@components/form/Form"
import { MediaResult } from "@components/media/upload/hooks/useMultipartUploadMediaToS3"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoText, DiscoTextSkeleton } from "@disco-ui"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import { useLazyLoadQuery } from "react-relay"
import { ConnectionHandler, graphql } from "relay-runtime"

export type AssetTrackFormState = Partial<CreateAssetTrackInput>

export type AssetTrackFormStore = FormStore<AssetTrackFormState>

interface Props {
  assetId: GlobalID
  onClose: VoidFunction
}

const TESTID = "CreateAssetTrackForm"

function CreateAssetTrackForm({ assetId, onClose }: Props) {
  const classes = useStyles()
  const [fileData, setFileData] = useState<{ url: string; name: string } | null>(null)

  const { node } = useLazyLoadQuery<CreateAssetTrackFormQuery>(
    graphql`
      query CreateAssetTrackFormQuery($assetId: ID!) {
        node(id: $assetId) {
          ... on Asset {
            __typename
            id
            ...AssetTrackFormLanguageSectionFragment
          }
        }
      }
    `,
    {
      assetId,
    }
  )
  const asset = Relay.narrowNodeType(node, "Asset")

  const form = useFormStore<CreateAssetTrackFormMutation, AssetTrackFormState>(
    graphql`
      mutation CreateAssetTrackFormMutation(
        $input: CreateAssetTrackInput!
        $connections: [ID!]!
      ) {
        response: createAssetTrack(input: $input) {
          node
            @appendNode(connections: $connections, edgeTypeName: "AssetTrackNodeEdge") {
            id
            url
            name
            languageCode
            videoAsset {
              id
              hasSubtitles
            }
            ...AssetTracksListItemFragment
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      videoAssetId: assetId,
      trackAssetId: "",
      languageCode: undefined,
      kind: "subtitles",
    }
  )

  const { closeModal } = useAssetSubtitlesModal()

  if (!asset) return null

  return (
    <Form
      id={TESTID}
      testid={TESTID}
      onSubmit={handleSubmit}
      buttons={
        <>
          <DiscoButton color={"grey"} variant={"outlined"} onClick={onClose}>
            {"Cancel"}
          </DiscoButton>
          <Form.SubmitButton
            form={form}
            id={TESTID}
            testid={`${TESTID}.submit-button`}
            disabled={!form.state.languageCode || !form.state.trackAssetId}
          >
            {"Add Subtitles"}
          </Form.SubmitButton>
        </>
      }
    >
      <AssetTrackFormLanguageSection form={form} assetKey={asset} />

      <DiscoText variant={"body-md-600"} marginBottom={0.5}>
        {"Add subtitles or caption media"}
      </DiscoText>
      <DiscoText variant={"body-sm"} color={"text.secondary"} marginBottom={2}>
        {"Help viewers understand and follow the narrative"}
      </DiscoText>
      {form.state.trackAssetId && fileData ? (
        <AttachmentListItem
          testid={`AssetTrackFormSubtitlesSection.subtitles`}
          attachment={{
            mediaUrl: fileData.url,
            name: fileData.name,
            type: "document",
          }}
          onClickDelete={handleRemoveUplaodedSubtitleFile}
          isCompact
        />
      ) : (
        <FileDropzone
          dropzoneOptions={{ accept: SUBTITLE_FILE_TYPES }}
          message={"Drop or upload a subtitle file"}
          className={classes.fileDropzone}
          onUpload={handleUploadSubtitleFile}
          onMediaSelect={handleUploadSubtitleFile}
          testid={"AssetTrackFormSubtitlesSection.FileDropzone"}
          hideMediaLibrary
          includeInMediaLibrary={false}
        />
      )}
    </Form>
  )

  function handleUploadSubtitleFile(result: MediaResult) {
    form.state.trackAssetId = result.id
    setFileData({ url: result.url, name: result.name })
  }

  function handleRemoveUplaodedSubtitleFile() {
    form.state.trackAssetId = ""
    setFileData(null)
  }

  async function handleSubmit() {
    const { didSave, response } = await form.submit(
      {
        videoAssetId: form.state.videoAssetId!,
        trackAssetId: form.state.trackAssetId!,
        languageCode: form.state.languageCode as LanguageCode,
        kind: "subtitles",
      },
      {
        connections: [
          ConnectionHandler.getConnectionID(assetId, "AssetTracksList__subtitleTracks"),
        ],
      }
    )

    if (!didSave || !response?.node) return

    displaySuccessToast({
      testid: `${TESTID}.success-toast`,
      message: "Subtitles added!",
    })

    closeModal()
  }
}

const useStyles = makeUseStyles({
  fileDropzone: {
    width: "100%",
  },
})

function CreateAssetTrackFormSkeleton() {
  const classes = useStyles()
  return (
    <>
      <AssetTrackFormLanguageSectionSkeleton />
      <DiscoTextSkeleton width={"200px"} marginBottom={0.5} />
      <DiscoTextSkeleton width={"300px"} marginBottom={2} />
      <FileDropzone
        dropzoneOptions={{ accept: SUBTITLE_FILE_TYPES }}
        message={"Drop or upload a subtitle file"}
        className={classes.fileDropzone}
        disabled
      />
    </>
  )
}

export default Relay.withSkeleton({
  component: observer(CreateAssetTrackForm),
  skeleton: CreateAssetTrackFormSkeleton,
})
