import { StartAssetTranscriptionJobButtonMutation } from "@/admin/asset/transcription/__generated__/StartAssetTranscriptionJobButtonMutation.graphql"
import { useFormStore } from "@/core/form/store/FormStore"
import { GlobalID } from "@/relay/RelayTypes"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import { rest } from "lodash"
import { observer } from "mobx-react-lite"
import { ConnectionHandler, graphql } from "react-relay"
interface Props {
  assetId: GlobalID
  children: OverridableDiscoButtonChildren
}
function StartAssetTranscriptionJobButton({ assetId, children }: Props) {
  const form = useFormStore<StartAssetTranscriptionJobButtonMutation>(
    graphql`
      mutation StartAssetTranscriptionJobButtonMutation(
        $input: StartAssetTranscriptionJobInput!
        $connections: [ID!]!
      ) {
        response: startAssetTranscriptionJob(input: $input) {
          node {
            id
            asset {
              ...InteractivePlayerTranscriptionStatusFragment
            }
          }
          assetTrack
            @appendNode(connections: $connections, edgeTypeName: "AssetTrackNodeEdge") {
            ...AssetTracksListItemFragment
            videoAsset {
              ...InteractivePlayerSubtitlesTabContentFragment
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      assetId,
    }
  )
  return (
    <OverridableDiscoButton
      {...rest}
      onClick={handleClick}
      shouldDisplaySpinner={form.isSubmitting}
    >
      {children}
    </OverridableDiscoButton>
  )
  async function handleClick() {
    const { didSave } = await form.submit(form.state, {
      connections: [
        ConnectionHandler.getConnectionID(assetId, "AssetTracksList__subtitleTracks"),
      ],
    })
    if (!didSave) return
    displaySuccessToast({
      message: `Generating transcription... This process may take a few minutes, but you can check back later.`,
      testid: `StartAssetTranscriptionJobButton.success-toast`,
    })
  }
}
export default observer(StartAssetTranscriptionJobButton)
