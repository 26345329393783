/**
 * @generated SignedSource<<36e423476e550049d4d747248f4a652c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BadgeKind = "emoji" | "icon" | "upload" | "%future added value";
export type LabelKind = "product_member" | "product_instructor" | "product_admin" | "organization_member" | "organization_admin" | "organization_owner" | "experience" | "admin_experience" | "admin_member" | "curriculum" | "custom" | "lesson" | "assignment" | "quiz" | "sidebar_community" | "sidebar_product" | "pathway" | "%future added value";
export type OrganizationCheckoutVersion = "stripe" | "stripe_acacia" | "%future added value";
export type OrganizationCurrency = "usd" | "cad" | "eur" | "aud" | "gbp" | "%future added value";
export type OrganizationVisibility = "public" | "private" | "%future added value";
export type ThemeKind = "light" | "dark" | "custom" | "%future added value";
export type UpdateOrganizationSettingsInput = {
  id: string;
  name?: string | null;
  slug?: string | null;
  description?: string | null;
  logo?: string | null;
  logoAssetId?: string | null;
  darkLogo?: string | null;
  darkLogoAssetId?: string | null;
  cover?: string | null;
  coverAssetId?: string | null;
  faviconUrl?: string | null;
  isDmEnabled?: boolean | null;
  isChannelsEnabled?: boolean | null;
  isProductsEnabled?: boolean | null;
  isExploreProductTabHidden?: boolean | null;
  isEventsEnabled?: boolean | null;
  showGlobalTabTitles?: boolean | null;
  billingContactEmail?: string | null;
  currency?: OrganizationCurrency | null;
  visibility?: OrganizationVisibility | null;
  checkoutVersion?: OrganizationCheckoutVersion | null;
  tracking?: UpdateTrackingInput | null;
  experienceNavOrdering?: ReadonlyArray<string> | null;
  badge?: BadgeInput | null;
  theme?: OrganizationSettingsThemeInput | null;
  label?: OrganizationSettingsLabelInput | null;
};
export type UpdateTrackingInput = {
  googleAnalyticsId?: string | null;
  facebookPixelId?: string | null;
  googleTagManagerId?: string | null;
};
export type BadgeInput = {
  icon?: string | null;
  color?: string | null;
  emoji?: string | null;
  mediaUrl?: string | null;
  assetId?: string | null;
  kind: BadgeKind;
};
export type OrganizationSettingsThemeInput = {
  primaryColor?: string | null;
  primaryContrastColor?: string | null;
  backgroundColor?: string | null;
  cardBackgroundColor?: string | null;
  navBackgroundColor?: string | null;
  kind?: ThemeKind | null;
};
export type OrganizationSettingsLabelInput = {
  labels: ReadonlyArray<UpdateLabelInput>;
};
export type UpdateLabelInput = {
  kind: LabelKind;
  singular: string;
  plural: string;
  description?: string | null;
};
export type AdminAppearancePageMutation$variables = {
  input: UpdateOrganizationSettingsInput;
};
export type AdminAppearancePageMutation$data = {
  readonly response: {
    readonly node: {
      readonly id: string;
      readonly logo: string | null;
      readonly darkLogo: string | null;
      readonly faviconUrl: string | null;
      readonly cover: string | null;
      readonly name: string;
      readonly badge: {
        readonly kind: BadgeKind;
        readonly icon: string | null;
        readonly color: string | null;
        readonly emoji: string | null;
        readonly mediaUrl: string | null;
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"CustomThemeProviderActiveOrganizationFragment" | "LabelsContextActiveOrganizationFragment">;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type AdminAppearancePageMutation = {
  variables: AdminAppearancePageMutation$variables;
  response: AdminAppearancePageMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logo",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "darkLogo",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "faviconUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cover",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emoji",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mediaUrl",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminAppearancePageMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrganizationSettingsMutationResponse",
        "kind": "LinkedField",
        "name": "updateOrganizationSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Badge",
                "kind": "LinkedField",
                "name": "badge",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CustomThemeProviderActiveOrganizationFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "LabelsContextActiveOrganizationFragment"
              }
            ],
            "storageKey": null
          },
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdminAppearancePageMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrganizationSettingsMutationResponse",
        "kind": "LinkedField",
        "name": "updateOrganizationSettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Badge",
                "kind": "LinkedField",
                "name": "badge",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Theme",
                "kind": "LinkedField",
                "name": "theme",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "primaryColor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "primaryContrastColor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "backgroundColor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cardBackgroundColor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "navBackgroundColor",
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "LabelNodeConnection",
                "kind": "LinkedField",
                "name": "labels",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LabelNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Label",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "singular",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "plural",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "852382b5fe6809248410d747cddf845a",
    "id": null,
    "metadata": {},
    "name": "AdminAppearancePageMutation",
    "operationKind": "mutation",
    "text": "mutation AdminAppearancePageMutation(\n  $input: UpdateOrganizationSettingsInput!\n) {\n  response: updateOrganizationSettings(input: $input) {\n    node {\n      id\n      logo\n      darkLogo\n      faviconUrl\n      cover\n      name\n      badge {\n        kind\n        icon\n        color\n        emoji\n        mediaUrl\n        id\n      }\n      ...CustomThemeProviderActiveOrganizationFragment\n      ...LabelsContextActiveOrganizationFragment\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n\nfragment CustomThemeProviderActiveOrganizationFragment on Organization {\n  theme {\n    primaryColor\n    primaryContrastColor\n    backgroundColor\n    cardBackgroundColor\n    navBackgroundColor\n    kind\n    id\n  }\n}\n\nfragment LabelsContextActiveOrganizationFragment on Organization {\n  labels {\n    edges {\n      node {\n        id\n        kind\n        singular\n        plural\n        description\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "904d8603e2e74a0f7b1158f54f9af439";

export default node;
